<template>
  <div>
    <b-overlay :show="isLoading" no-wrap fixed z-index="1100"></b-overlay>
    <b-modal
      size="lg"
      id="edit-incident-modal"
      :title="$t('edit-incident-modal.title')"
      :ok-title="$t('edit-incident-modal.ok-title')"
      :cancel-title="$t('edit-incident-modal.cancel-title')"
      @ok.prevent="onUpdateIncident"
      scrollable
    >
      <template v-slot:default>
        <b-form-group :label="$t('edit-incident-modal.type')">
          <v-select
            :value="incidentType"
            :clearable="false"
            :options="incidentTypes"
            :getOptionLabel="x => x.title"
            :disabled="isDriver"
            @input="
              x => {
                incident.type = x.type;
                incident.issueCategoryId = x.categoryId || null;
              }
            "
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.status')">
          <v-select v-model="incident.status" :options="incidentStatuses" :reduce="x => x.key" :disabled="isDriver">
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.vehicle')">
          <v-select v-model="incident.vehicle.id" :options="vehicles" :reduce="x => x.key" :disabled="isDriver">
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.responsible-user')" v-if="!isDriver">
          <v-select
            :clearable="false"
            :value="responsibleUserName"
            :options="drivers"
            @input="x => (incident.responsibleUser = { id: x.id, userName: x.userName })"
            label="userName"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.description')">
          <b-form-textarea :disabled="partialEdit" v-model="incident.description" />
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.incident-responsible-user')" v-if="!isDriver">
          <v-select
            :value="incidentResponsibleUserName"
            :options="incidentResponsibleUsers"
            @input="
              x => {
                x
                  ? (incident.incidentResponsibleUser = { id: x.id, userName: x.userName })
                  : (incident.incidentResponsibleUser = null);
              }
            "
            label="userName"
          >
            <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
          </v-select>
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.resolution')" :disabled="isDriver">
          <b-form-textarea v-model="incident.resolution" />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="incident.isUrgent">
            {{ $t("edit-incident-modal.is-urgent") }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.last-changes-date')">
          {{ convertDateToString(incident.modifiedAt) }}
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.time-created')">
          {{ convertDateToString(incident.reportedAt) }}
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.user-name')">
          {{ incident.reportedBy.userName }}
          <phone-number :phoneNumber="incident.reportedBy.phone"></phone-number>
        </b-form-group>
        <b-form-group :label="$t('edit-incident-modal.files')" v-if="partialEdit === false">
          <input
            multiple
            type="file"
            ref="fileInput"
            class="d-none"
            accept="image/*, video/*"
            @change="onChangeUploadFiles"
          />
          <b-button @click="$refs.fileInput.click()">{{ $t("edit-incident-modal.add-file-btn") }}</b-button>
          <ul class="mt-2">
            <li v-for="(x, i) in newIncidentFiles" :key="i">{{ x.name }}</li>
          </ul>
        </b-form-group>
        <b-form-group>
          <attachment-viewer :incidentId="incident.id" :files="incident.files" />
        </b-form-group>
        <driver-actions :items="driverActions"></driver-actions>
        <load-actions :items="loadActions"></load-actions>
        <b-button v-b-modal.remove-incident-modal v-if="isAdministrator" variant="danger">{{
          $t("edit-incident-modal.remove-btn")
        }}</b-button>
        <b-modal
          id="remove-incident-modal"
          :title="$t('edit-incident-modal.remove-modal.title')"
          :ok-disabled="incidentIdForRemove != incident.title"
          :ok-title="$t('edit-incident-modal.remove-modal.ok-btn')"
          :cancel-title="$t('edit-incident-modal.remove-modal.cancel-btn')"
          ok-variant="danger"
          @ok="remove"
          @hide="incidentIdForRemove = ''"
        >
          <template v-slot:default>
            <b-form-group :label="$t('edit-incident-modal.remove-modal.body')">
              <b-form-input v-model="incidentIdForRemove"></b-form-input>
            </b-form-group>
          </template>
        </b-modal>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import AttachmentViewer from "./attachment/AttachmentViewer.vue";
import DriverActions from "./DriverActions.vue";
import PhoneNumber from "@/components/PhoneNumber.vue";
import LoadActions from "./LoadActions.vue";

import api from "@/services/api.js";
import auth from "@/services/auth.js";

import { DRIVER, ADMINISTRATOR, MANAGER } from "@/const/user-role.js";
import { OPEN_EDIT_INCIDENT_MODAL, UPDATE_INCIDENT_LIST } from "@/const/events.js";
import { CLOSED, OPEN, IN_PROCESS, NEXT_THING_TODO } from "@/const/incident-statuses.js";
import {
  SALES_INCIDENT_TYPE,
  REPAIR_INCIDENT_TYPE,
  TRIM_LEVEL_INCIDENT_TYPE,
  CUSTOM_INCIDENT_TYPE
} from "@/const/incident-types.js";

export default {
  components: {
    AttachmentViewer,
    DriverActions,
    PhoneNumber,
    LoadActions
  },
  data() {
    return {
      drivers: [],
      incidentResponsibleUsers: [],
      driverActions: [],
      loadActions: [],
      vehicles: [],
      newIncidentFiles: [],
      incident: {
        type: "",
        issueCategoryId: null,
        vehicle: {
          id: NaN,
          registrationNumber: ""
        },
        status: "",
        description: "",
        responsibleUser: {
          id: NaN,
          userName: ""
        },
        incidentResponsibleUser: {
          id: NaN,
          userName: ""
        },
        isUrgent: false
      },
      incidentIdForRemove: "",
      isLoading: false
    };
  },
  computed: {
    isDriver() {
      return auth.userRole() === DRIVER;
    },
    isAdministrator() {
      return auth.userRole() === ADMINISTRATOR;
    },
    isManager() {
      return auth.userRole() === MANAGER;
    },
    issueCategories() {
      return this.$store.state.issueCategories.items;
    },
    incidentType() {
      return this.incidentTypes.find(
        x => x.type == this.incident.type && x.categoryId == this.incident.issueCategoryId
      );
    },
    incidentTypes() {
      let result = [
        { type: SALES_INCIDENT_TYPE, title: this.$t("incident-type.sales") },
        { type: REPAIR_INCIDENT_TYPE, title: this.$t("incident-type.repair") },
        { type: TRIM_LEVEL_INCIDENT_TYPE, title: this.$t("incident-type.trim-level") }
      ];
      for (const category of this.issueCategories) {
        result.push({ title: category.title[this.$i18n.locale], type: CUSTOM_INCIDENT_TYPE, categoryId: category.id });
      }
      return result;
    },
    incidentStatuses() {
      let statuses = [
        { key: CLOSED, label: this.$t("incident-status.closed") },
        { key: OPEN, label: this.$t("incident-status.open") },
        { key: IN_PROCESS, label: this.$t("incident-status.in-process") }
      ];

      if (this.isAdministrator || this.isManager) {
        statuses.push({ key: NEXT_THING_TODO, label: this.$t("incident-status.next-thing-todo") });
      }

      return statuses;
    },
    incidentResponsibleUserName() {
      return this.incident && this.incident.incidentResponsibleUser
        ? this.incident.incidentResponsibleUser.userName
        : null;
    },
    responsibleUserName() {
      return this.incident && this.incident.responsibleUser ? this.incident.responsibleUser.userName : null;
    },
    partialEdit() {
      return this.incident.status === OPEN ? this.incident.reportedBy.userName !== auth.userName() : true;
    },
    validIncidentData() {
      let isValidIncidentData =
        this.incident.type && this.incident.status && !isNaN(this.incident.vehicle.id) && this.incident.description;

      return this.incident.status === CLOSED ? isValidIncidentData && this.incident.resolution : isValidIncidentData;
    }
  },
  methods: {
    ...mapActions(["incidents/remove"]),
    remove() {
      this["incidents/remove"](this.incident.id)
        .then(() => {
          this.$bvModal.hide("edit-incident-modal");
          this.$bus.emit(UPDATE_INCIDENT_LIST);
        })
        .catch(() => {
          alert(this.$t("edit-incident-modal.remove-error"));
        });
    },
    onSelectResponsibleUser(userId) {
      this.incident.responsibleUser.id == userId;
    },
    convertDateToString(date) {
      if (date === null) return "";
      return this.$d(new Date(date), "long", this.$i18n.locale);
    },
    openModal(incident) {
      this.incident = incident;

      api.getVehiclesRegistrationNumbers().then(resp => {
        this.vehicles = resp.data.map(x => {
          return { key: x.vehicleId, label: x.registrationNumber };
        });
      });

      api.getAllUsers().then(resp => {
        this.drivers = resp.data
          .filter(x => x.role == DRIVER)
          .map(x => {
            return { id: x.id, userName: x.userName };
          });

        this.incidentResponsibleUsers = resp.data
          .filter(x => x.role != DRIVER)
          .map(x => {
            return { id: x.id, userName: x.userName };
          });
      });

      this.$store.dispatch("vehicleLoadAudit/getDriverActions", incident.vehicle.id).then(resp => {
        this.driverActions = resp.data;
      });

      this.$store.dispatch("vehicleLoadAudit/getLoadActions", incident.vehicle.id).then(resp => {
        this.loadActions = resp.data;
      });

      this.$bvModal.show("edit-incident-modal");
    },
    onChangeUploadFiles(e) {
      const maxFileGroupSize = 20000000;
      let totalFileSize = 0;

      e.target.files.forEach(x => (totalFileSize += x.size));

      if (totalFileSize > maxFileGroupSize) {
        alert(this.$t("edit-incident-modal.file-too-big"));
      } else {
        this.newIncidentFiles = e.target.files;
      }
    },
    onUpdateIncident() {
      if (this.validIncidentData === false) {
        alert(this.$t("edit-incident-modal.wrong-form"));
        return;
      }

      this.isLoading = true;
      let vm = new FormData();

      this.newIncidentFiles.forEach(x => vm.append("files", x, x.name));
      vm.append("id", this.incident.id);
      vm.append("vehicle.id", this.incident.vehicle.id);
      vm.append("status", this.incident.status);
      vm.append("type", this.incident.type);
      vm.append("issueCategoryId", this.incident.issueCategoryId);
      vm.append("description", this.incident.description);
      vm.append("isUrgent", this.incident.isUrgent);

      if (this.incident.resolution) vm.append("resolution", this.incident.resolution);
      if (this.incident.responsibleUser) vm.append("responsibleUser.id", this.incident.responsibleUser.id);

      vm.append(
        "incidentResponsibleUserId",
        this.incident.incidentResponsibleUser && this.incident.incidentResponsibleUser.id
      );

      this.$store
        .dispatch("incidents/update", vm)
        .then(() => {
          this.$bus.emit(UPDATE_INCIDENT_LIST);
          this.$bvModal.hide("edit-incident-modal");
          this.$emit("close");
        })
        .catch(() => {
          alert(this.$t("edit-incident-modal.wrong-edit-incident"));
        })
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    this.$bus.on(OPEN_EDIT_INCIDENT_MODAL, this.openModal);
    this.$store.dispatch("issueCategories/load");
  },
  beforeDestroy() {
    this.$bus.off(OPEN_EDIT_INCIDENT_MODAL, this.openModal);
  }
};
</script>
