<template>
  <a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>
</template>

<script>
export default {
  props: {
    phoneNumber: { required: true, type: String }
  }
};
</script>
