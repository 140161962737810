<template>
  <div>
    <div>
      <b-button b-button v-b-toggle.driver-actions-table-collapse class="m-1">
        {{ $t("edit-incident-modal.driver-actions-table-caption") }}
      </b-button>
    </div>
    <b-container fluid class="overflowable p-0">
      <b-row>
        <b-col>
          <b-collapse id="driver-actions-table-collapse">
            <b-table :fields="fields" :items="items" responsive>
              <template v-slot:cell(acceptedAt)="row">
                {{ $d(new Date(row.item.acceptedAt), "log", $i18n.locale) }}
              </template>
              <template v-slot:cell(checkedoutAt)="row">
                {{ $d(new Date(row.item.checkedoutAt), "log", $i18n.locale) }}
              </template>
              <template v-slot:cell(phone)="row">
                <phone-number :phoneNumber="row.value"></phone-number>
              </template>
            </b-table>
          </b-collapse>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PhoneNumber from "@/components/PhoneNumber.vue";

export default {
  components: {
    PhoneNumber
  },
  props: {
    items: { type: Array, default: () => [] }
  },
  computed: {
    fields() {
      return [
        { key: "acceptedAt", label: this.$t("edit-incident-modal.accepted-at"), sortable: true },
        { key: "checkedoutAt", label: this.$t("edit-incident-modal.checkedout-at"), sortable: true },
        { key: "user", label: this.$t("edit-incident-modal.user"), sortable: true },
        { key: "phone", label: this.$t("edit-incident-modal.phone"), sortable: true }
      ];
    }
  }
};
</script>
