<template>
  <b-card :title="$t('attachment.title')" v-show="files.length > 0">
    <template v-slot:default>
      <ul>
        <li v-for="(x, i) in files" :key="i">
          <b-link @click="downloadFile(x.id, x.originalFileName)">{{ x.originalFileName }}</b-link>
        </li>
      </ul>
    </template>
  </b-card>
</template>

<script>
import file from "@/services/file.js";

import { mapActions } from "vuex";

export default {
  props: {
    incidentId: { required: true, type: Number },
    files: { required: true, type: Array }
  },
  methods: {
    ...mapActions(["incidents/downloadFile"]),
    downloadFile(fileId, fileName) {
      let vm = { incidentId: this.incidentId, fileId };

      this["incidents/downloadFile"](vm).then(resp => {
        file.download({ fileName, content: resp.data });
      });
    }
  }
};
</script>
